<template>
    <el-dropdown @command="setLang" placement="bottom" class="margin-l" effect="light"
        v-if="!$store.state.isTw">
        <div class="tag active pointer" style="font-size: 16px;">{{ text }}</div>
        <el-dropdown-menu slot="dropdown" class="language">
            <el-dropdown-item :command="item.val" v-for="(item, index) in langs"
                :class="index == 0 ? 'margin-ts' : 'margin-t'" :key="index">
                <div class="tag item hover" :class="{ 'active': item.val == lang }">{{ item.label }}</div>
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
export default {
    name: "language",
    props: {

    },
    data() {
        return {
        };
    },


    computed: {
        lang: function () {
            return this.$store.state.lang
        },
        text: function () {
            return this.langs.find((val) => { return val.val == this.lang }).label
        },
        langs: function () {
            if (this.$store.state.mode == 'CCL') {
                return [{ val: '', label: '简体' }, { val: 'tw', label: '繁体' }]
            }
            return [{ val: '', label: '简体' }, { val: 'tw', label: '繁体' }, { val: 'en', label: 'English' }]
        }
    },
    methods: {
        setLang(val) {
            this.$store.commit('setLang', val)
        }
    },
};
</script>
<style scoped>
.language>>>.el-dropdown-menu__item:hover {
    background: inherit !important;
}

.tag {
    color: #999;
    text-align: center;
    line-height: 20px;
    padding: 5px 15px;
    display: inline-block;
    border-radius: 15px;
    border: 1px solid #999;
}

.tag.item {
    width: 50px;
}

.tag.active {
    color: #f66;
    border: 1px solid #f66;
}

.tag.hover:hover{
    border: 1px solid #f66;
    background-color: #f66;
    color: #fff;
}
</style>